import { client } from "@api/client";
import { PAGES } from "@api/queries";
import { FILTER_COLLECTIONS } from "@api/queries/collections";
import { DOMAINS, SITE } from "@api/queries/sites";
import { PageRender } from "@mosanic/core/Page/Render";

import {
  findPage,
  getCollectionsPathMap,
  getCustomDomains,
  getDynamicPart,
  getIndexPageId,
  getSitesDomainPathMap,
  getSubdomains
} from "@mosanic/utils/slug";

import { slugify, toLowCase } from "@util/Text";


export default function Index({ data, collection, entry, page, header, footer}) {
  header = header ? JSON.parse(header) : null;
  footer = footer ? JSON.parse(footer) : null;

  page = page ? JSON.parse(page) : null;
  data = data ? JSON.parse(data) : null;

  collection = collection ? JSON.parse(collection) : null;
  entry = entry ? JSON.parse(entry) : null;

  return ( 
    <PageRender 
      page={page}
      seo={page?.seo}
      entry={entry}
      headerPage={header}
      footerPage={footer}
      site={data}
    />
  )
}

 



export const getStaticPaths = async () => {
          const website = await client.query({query: SITE, variables: {_id: '62e7a50082b1bd8d84a13845'} })
  const site = website.data.siteById
  const filter = {catchRoutes: true}

    const collectionsQuery = await client.query({query: FILTER_COLLECTIONS, variables: {filter} })
    const collections = collectionsQuery.collectionMany

  const sites = [site];

  const subdomains = getSubdomains(sites)
  const customDomains = getCustomDomains(sites)

  const paths = [
    ...getSitesDomainPathMap(subdomains, 'subdomain'),
    ...getSitesDomainPathMap(customDomains, 'customDomain'),
    ...getCollectionsPathMap(sites, collections),
  ].flat();

  return { 
    paths,
    fallback: true, // allows ISRg
    
  }
}; 

export const getStaticProps = async ({params}) => {
  // if (!params) throw new Error("No path parameters found");
    const site = await client.query({query: SITE, variables: {_id: '62e7a50082b1bd8d84a13845'} })
  const website = site.data.siteById
  //Params
  const { slug } = params;


  const filter = {
    siteId: website?._id
  }
 

  const pageQuery = await client.query({query: PAGES, variables: {filter}})
  const pages = pageQuery.data.pageMany

  let page = slug ? pages?.find(page => toLowCase(page?.seo?.slug) === slug[0]) : null

  //Get Fallback pages
  const noPage = getIndexPageId(website, '404');
  const homePage = getIndexPageId(website, 'home');
  
  if(!page && noPage && slug) page = findPage(pages, noPage);
  if(!page) page = homePage ? findPage(pages, homePage) : pages?.[0];

  const header = getDynamicPart(website, pages, 'header');
  const footer = getDynamicPart(website, pages, 'footer');

  let filterCollections
  let collections
  let collection
  let entry

  //Todo format to handle also sub/set paths without dynamic entry handling
  //todo handle index pages?
  if(slug?.length >= 1) {
    filterCollections = {catchRoutes: true, siteId: website?._id, baseRoute: slug[0]}
    const collectionsQuery = await client.query({query: FILTER_COLLECTIONS, variables: {filter: filterCollections} })
    const collections = collectionsQuery.collectionMany
    collection = collections?.[0];
    entry = collection?.entries?.find(entry => slugify(toLowCase(entry?.[collection?.schema[0]?.name])) === slug[1]);
  }


  return {
    props: {
      analytics: {
        gaMeasurementId: "G-ZQS1M1QEYL",
        analyticsId: "9a764442-556f-4d3c-9f46-801375ba9732",
      },
      data: website ? JSON.stringify(website) : null,
      // slug: slug ? JSON.stringify(entry) : null,
      collection: collection ? JSON.stringify(collection) : null,
      entry: entry ? JSON.stringify(entry) : null,
      // site,
      // stringifiedPage: 'test data'
      page: page ? JSON.stringify(page) : null,
      header: header ? JSON.stringify(header) : null,
      footer: footer ? JSON.stringify(footer) : null,
    },
    revalidate: 120, //1800 30min
  };

};
  